// if (!Element.prototype.matches) {
//   Element.prototype.matches = Element.prototype.msMatchesSelector ||
//                               Element.prototype.webkitMatchesSelector;
// }

// const header = document.querySelector('#header');

document.addEventListener("DOMContentLoaded", function() {

  // Set css variable --header-height
  // var root = document.documentElement;
  // root.style.setProperty('--header-height', header.offsetHeight + "px");

  // toggles Consultation Modal
  const consultToggles = document.querySelectorAll('.consult-toggle');

  var toggleConsultModal = function(e) {
    e.preventDefault();
  	document.querySelector('body').classList.toggle('consultation-overlay-open');
  }

  for (var i = 0; i < consultToggles.length; ++i) {
    consultToggles[i].onclick = toggleConsultModal;
  }

  document.querySelector('#consultation-close-bg').onclick = toggleConsultModal;
  document.querySelector('#consultation-overlay .close').onclick = toggleConsultModal;


  // var consultModal = document.querySelector('#consultation-overlay');
  // consultModal.onclick = toggleConsultModal;

  // hides Consultation Modal with Esc keypress
  document.onkeydown = function(evt) {
      evt = evt || window.event;
      var isEscape = false;
      if ("key" in evt) {
          isEscape = (evt.key === "Escape" || evt.key === "Esc");
      } else {
          isEscape = (evt.keyCode === 27);
      }
      if (isEscape) {
          document.querySelector('body').classList.remove('consultation-overlay-open');
      }
  };

  const menuToggle = document.querySelector('.menu-toggle');

  menuToggle.onclick = function() {

    document.querySelector('body').classList.toggle('menu-open');
    
  }
  
});